import {
  TextField,
  List,
  DateField,
  TopToolbar,
  ExportButton,
  FunctionField,
  Pagination,
} from 'react-admin';
import { TransactionFilterButton } from '../transaction';
import { Box } from '@mui/material';
import { BusinessAccountFilterForm } from '../business-account';
import { AccountTotalBalance, IdTypeEnum } from '../balance';
import { fullName } from '../../@helpers/wallet.helpers';
import { AccountType, owner } from '../../@helpers/account.helpers';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

const ListActions = (props: any) => (
  <Box width="100%">
    <TopToolbar>
      <TransactionFilterButton/>
      <ExportButton/>
    </TopToolbar>
    {props.form}
  </Box>
);

export const OrganizationName = (props: any) => {
  const record = props.record;
  // if (record.singleOwner) {
  //   return null;
  // }
  return (<TextField record={record} source="legalName"/>)
}

export const getKycStatus = (record: any) => {
  return record.internalKycStatus || record.kycStatus
}

const BusinessAccountsListFields = () => {
  return [
    <TextField key="id" source="id"/>,
    <TextField key="accountNumber" source="accountNumber"/>,
    <FunctionField key="accountType" label="Client type" render={(record: any) => AccountType(record)}/>,
    <AccountTotalBalance key="balance" label="Balance" idType={IdTypeEnum.BUSINESS_ACCOUNT}/>,
    <FunctionField key="accountEmail" label="Account Email" render={(record: any) => owner(record)?.email}/>,
    <FunctionField key="legalName" label="Legal name" render={(record: any) => <OrganizationName record={record}/>}/>,
    <FunctionField key="UserName" label="User Name" render={(record: any) => fullName(owner(record))}/>,
    <FunctionField key="userStatus" label="User status" render={(record: any) => owner(record)?.status}/>,
    <FunctionField key="userEmail" label="User Email" render={(record: any) => owner(record)?.profile?.email}/>,
    <FunctionField key="userDeletionState" label="User deletion state"
                   render={(record: any) => owner(record)?.deletedAt ? 'Deactivated' : 'Active'}/>,
    <DateField key="formationDate" source="formationDate"/>,
    <FunctionField key="kycStatus" label="KYC status" sortBy="kycStatus" render={getKycStatus}/>,
    <TextField key="sumsubApplicantId" source="sumsubApplicantId"/>,
    <DateField key="createdAt" source="createdAt"/>,
    <DateField key="updatedAt" source="updatedAt"/>,
  ]
}

export const BusinessAccountList = () => {
  return (
    <List actions={<ListActions form={<BusinessAccountFilterForm/>}/>} sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={BusinessAccountsListFields()} rowClick="show" bulkActionButtons={false}/>
    </List>
  )
}