import { List, Datagrid, TextField, DateField, FunctionField, Pagination } from 'react-admin';
import { ITransactionModel } from '../transaction/domain/transaction.model';
import { senderName, TransactionAddressField } from '../transaction';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const TransactionHandlingList=()=>{
  return(
    <List sort={{ field: 'createdAt', order: 'DESC' }} pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="type"/>
        <TextField source="sendAsset"/>
        <TextField source="sendAmountGross"/>
        <TextField source="receivedAmountGross"/>
        <TextField source="fee"/>
        <TextField source="state"/>
        <TextField label="Status" source="payload.txSubStatus"/>
        <TransactionAddressField key="source" label="Source" sortBy="source" source="source"/>
        <TransactionAddressField key="destination" label="Destination" sortBy="destination" source="destination"/>
        <TextField source="source"/>
        <TextField source="destination"/>
        <TextField source="reference"/>
        <TextField source="beneficiary"/>
        <FunctionField label="Sender" sortBy="senderId" render={(record: ITransactionModel) => senderName(record)}/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>

    </List>
  )
}