import { DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";

export const AssetAliasTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Asset Service "{record.serviceName}"</span>;
};

export const AssetAliasShow = () => {
  return (
    <Show title={<AssetAliasTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="serviceName"/>
        <TextField source="serviceCode"/>
        <ReferenceField source="assetId" reference="asset">
          <TextField source="title"/>
        </ReferenceField>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}