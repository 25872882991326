import { Datagrid, DateField, List, Pagination, TextField, useRecordContext } from 'react-admin'
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const SimpleContentView = (props: any) => {
  const record = useRecordContext();
  const { source } = props;
  if (!record) return null;
  const updatedRecord = { ...record };
  updatedRecord[source] = record[source].slice(0, 50) + '...';
  return (
    <TextField record={updatedRecord} source={source}/>
  )
}

export const DocumentList = () => {
  return (
    <List pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Document Id" source="id"/>
        <TextField label="Document type" source="type"/>
        <SimpleContentView label="Document content" source="content"/>
        <DateField label="Created date" source="createdAt"/>
        <DateField label="Updated date" source="updatedAt"/>
      </Datagrid>
    </List>
  )
}