import { Box } from '@mui/material';
import { Datagrid, ExportButton, List, TextField, TopToolbar } from "react-admin";

const ListActions = (props: any) => (
  <Box width="100%">
    <TopToolbar>
      <ExportButton/>
    </TopToolbar>
  </Box>
);

const BalancesReportList = (props: any) => {

  return (
    <List {...props} actions={<ListActions/>}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="asset"/>
        <TextField source="balance"/>
      </Datagrid>
    </List>
  );
}

export default BalancesReportList;
