import { DeleteWithConfirmButton, SaveButton, Toolbar, useRecordContext } from "react-admin";

export const EditToolBar = (props: any) => {
  const record = useRecordContext();

  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton/>
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        translateOptions={{ name: record.bannerName }}
      />
    </Toolbar>
  )
}