import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { differenceInCalendarDays } from 'date-fns';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { AdminResourceName } from '../../resource-name.enum';

export const SavingDuration = (props: any) => {
  const record: { maturityDate: string; createdAt: string } = useRecordContext();
  if (!record) return null;

  if (!record.maturityDate) {
    return <span> Flexible </span>;
  }

  const diff = differenceInCalendarDays(new Date(record.maturityDate), new Date(record.createdAt));
  return <span>{diff} days</span>;
};

export const SavingDaysLeft = (props: any) => {
  const record: { maturityDate: string } = useRecordContext();
  if (!record) return null;
  let daysLeft = differenceInCalendarDays(new Date(record.maturityDate), Date.now());
  let dayText = 'days';
  if (daysLeft === 1) {
    dayText = 'day';
  }
  if (daysLeft < 0) {
    daysLeft = 0;
  }
  return (
    <span>
      {daysLeft} {dayText}
    </span>
  );
};

export const SavingList = () => {
  return (
    <List pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Saving ID" source="id" />
        <TextField label="Wallet ID" source="walletId" />
        <SavingDuration label="Duration" sortable={false} sortBy='maturityDate'/>
        <TextField label="Interest" source="percentage" />
        <ReferenceField
          key="asset"
          label="Currency"
          source="walletId"
          reference={AdminResourceName.WALLET}
          link={false}
        >
          <FunctionField render={(record: any) => record.asset} />
        </ReferenceField>
        <DateField label="Start date" source="createdAt" />
        <DateField label="Finish date" source="maturityDate" />
        <DateField label="Withdraw date" source="withdrawDate" />
        <SavingDaysLeft label="Days left" sortable={true} sortBy='maturityDate'/>
        <ReferenceField
          label="Client's name"
          source="user.id"
          reference={AdminResourceName.USER}
          sortable={false}
          link={(record) => {
            return `/business_account/${record.wallet.accountId}/show`;
          }}
        >
          <FunctionField
            render={(record: any) => {
              return [record.profile.firstName, record.profile.lastName].filter((v) => !!v).join(' ');
            }}
          />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
