import { Layout } from 'react-admin';
import { CustomMenu } from './main-menu';

const MyLayout = (props: any) => <Layout
    {...props}
    // appBar={MyAppBar}
    // sidebar={MySidebar}
    menu={CustomMenu}
/>;

export default MyLayout;
