import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { config } from "../../config";
import { AdminResourceName } from '../../resource-name.enum';

export const createIconFields =[
  <TextInput key='type' label="Type" source="type"/>,
  <TextInput key='content' label="Content" source="content"/>,
]

export const IconCreate = () => {
  const iconFields = [...createIconFields];
  if(config.MODULES.INERCASH_CARD){
    iconFields.push(
      <ReferenceInput key='cardDeliverySettingId' name="cardDeliverySettingId" source="cardDeliverySettingId" reference={AdminResourceName.INTERCASH_DELIVERY} perPage={100}>
        <SelectInput label="DeliveryType" optionText="deliveryMethod" optionValue="id"/>
      </ReferenceInput>
    )
  }
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        {iconFields}
      </SimpleForm>
    </Create>
  )
}