import { BusinessAccountType } from '../components/business-account';

export function owner(record: any): any {
  return record.singleOwner || record.users.map((u: any) => u.user)?.[0];
}

export function AccountType(record: any): BusinessAccountType {
  if (!record.singleOwner) {
    return BusinessAccountType.COMPANY;
  }
  return BusinessAccountType.INDIVIDUAL
}