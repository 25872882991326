import { Box } from '@mui/material';
import { TopToolbar } from 'react-admin';

export const CustomTopToolbar = (props: any) => {
  const { filterButton,filterForm, exportButton,createButton}=props;
  return (
    <Box width="100%">
      <TopToolbar>
        {filterButton}
        {createButton}
        {exportButton}
      </TopToolbar>
      {filterForm}
    </Box>
  );}