import {
  AutocompleteInput,
  BooleanInput,
  DeleteButton,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
} from 'react-admin'
import { SavingPlanTitle, transformFormData } from '../saving-plan';
import { transformNumberToString } from '../../@helpers/transform-number-to-string';
import { Toolbar } from '@mui/material';
import { useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';

export const validateSavingPlanForm = ({ assetId, yearPercentage, duration, minimumAmount, isTermless }: any) => {
  const errors = {};
  if (!assetId) {
    Object.assign(errors, {
      assetId: 'Asset is required',
    });
  }
  if (!yearPercentage) {
    Object.assign(errors, {
      yearPercentage: 'Year percentage is required',
    });
  }
  if (!duration && !isTermless) {
    Object.assign(errors, {
      duration: 'Duration is required',
    });
  }
  if (!minimumAmount) {
    Object.assign(errors, {
      minimumAmount: 'Minimum amount is required',
    });
  }
  if (yearPercentage === 0) {
    Object.assign(errors, {
      yearPercentage: 'Year percentage must be non zero value',
    });
  }
  if (minimumAmount === '0') {
    Object.assign(errors, {
      minimumAmount: 'Minimum amount must be non zero value',
    });
  }
  return errors;
}

export const SavingPlanEditToolBar = (props: any) => {
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton/>
      <DeleteButton/>
    </Toolbar>
  )
}

export const SavingPlanEdit = () => {
  const [isTermless, setIsTermless] = useState(false)
  return (
    <Edit title={<SavingPlanTitle/>} transform={transformFormData} mutationMode="pessimistic">
      <SimpleForm validate={validateSavingPlanForm} toolbar={<SavingPlanEditToolBar/>}>
        <ReferenceInput source="assetId" reference={AdminResourceName.ASSET}>
          <AutocompleteInput name="assetId" helperText={false} label="Asset" optionText={(choice:any)=>`${choice.title}: ${choice.shortcode}`} optionValue="id"
                             filterToQuery={(value: any) => ({ title: value })}/>
        </ReferenceInput>
        <NumberInput source="yearPercentage" min={0} parse={transformNumberToString}/>
        <BooleanInput source="isTermless" onChange={() => {
          setIsTermless(!isTermless)
        }}/>
        <NumberInput source="duration" min={1} disabled={isTermless}/>
        <NumberInput source="minimumAmount" min={0} parse={transformNumberToString}/>
      </SimpleForm>
    </Edit>
  )
}