import {
  SelectInput,
  TextInput,
  useListContext,
} from "react-admin";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid, InputAdornment } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { userStateChoices } from "../user";
import { useEffect } from 'react';

const formDefaultValues={
  id:'',
  name:'',
  status:'',
  email:'',
}

export const UserFilterForm = () => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter
  } = useListContext();
  const form = useForm({
    defaultValues: filterValues,
  });

  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset(formDefaultValues);
  }, [form.reset])

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    // @ts-ignore
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={3} mr={1}>
            <TextInput
              resettable
              helperText={false}
              source="id"
              label="User Id"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3} mr={1}>
            <TextInput
              resettable
              helperText={false}
              source="name"
              label="User name"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3} mr={1}>
            <SelectInput label="User action state" helperText={false} source="status"
                         choices={userStateChoices}/>
          </Grid>
          {/*{permissions === AdminRole.SUPER_ADMIN && <Grid xs={2}  mr={1}>*/}
          {/*    <SelectInput label="2fa state" helperText={false} source="isTwoFactorAuthEnabled"*/}
          {/*                 choices={BooleanStateTypes}/>*/}
          {/*</Grid>*/}
          {/*}*/}
          <Grid item xs={3}  mr={1}>
            <TextInput
              resettable
              helperText={false}
              source="email"
              label="Email"
            />
          </Grid>
          {/*<Grid xs={3}  mr={1}>*/}
          {/*  <SelectInput label="KYC Status"*/}
          {/*               helperText={false}*/}
          {/*               source="kycStatus"*/}
          {/*               choices={KycStatusTypes}/>*/}
          {/*</Grid>*/}
          {/*<Grid xs={3}  mr={1}>*/}
          {/*  <TextInput*/}
          {/*    resettable*/}
          {/*    helperText={false}*/}
          {/*    source="refCurrency"*/}
          {/*    label="Reference Currency"*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*{permissions === AdminRole.SUPER_ADMIN && <Grid xs={3}  mr={1}>*/}
          {/*    <SelectInput*/}
          {/*        helperText={false}*/}
          {/*        source="deleted"*/}
          {/*        choices={UserStateFilterChoices}*/}
          {/*        label="By State"*/}
          {/*    />*/}
          {/*</Grid>}*/}
          {/*<Grid xs={3}  mr={1}>*/}
          {/*  <DateTimeInput label="Date From" helperText={false} name="dateFrom" source="dateFrom" defaultValue=""/>*/}
          {/*</Grid>*/}
          {/*<Grid xs={4}  mr={1}>*/}
          {/*  <DateTimeInput label="Date To" helperText={false} name="dateTo" source="dateTo" defaultValue=""/>*/}
          {/*</Grid>*/}
          <Grid item xs={1}  mr={1}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Grid>
          <Grid item xs={1}  mr={1}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}