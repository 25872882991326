import { CustomRoutes, Resource } from 'react-admin';
import React from 'react';
import { COMMON_RESOURCES, INTERCASH_RESOURCES } from '../resources';
import { AdminRole, RolePermissions } from '../components/role';
import {
  excludeContentByActionPermission,
  filterContentByPermissions,
  removeEmptyContentResource
} from '../@helpers/filter-content-by-role';
import { Route } from 'react-router-dom';
import { TwoFactorPage } from '../components/login';

export const MainView = (config: any, permissions: AdminRole) => {
  const propsView = [
    ...COMMON_RESOURCES,

  ];
  const rolePermissions = RolePermissions[permissions] ? RolePermissions[permissions] : []

  if (config.MODULES.INERCASH_CARD) {
    propsView.push(...INTERCASH_RESOURCES)
  }
  const contentToDraw = removeEmptyContentResource(excludeContentByActionPermission(filterContentByPermissions(propsView, rolePermissions), rolePermissions));
  const resources = contentToDraw.map((props, index) => <Resource key={index} {...props}/>)
  return [
    <CustomRoutes noLayout>
      <Route path="/enable_2fa" element={<TwoFactorPage/>}/>
    </CustomRoutes>,
    ...resources,
  ]
}
