import { Box, Button, InputAdornment } from '@mui/material';
import { SelectInput, TextInput, useListContext } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { KycStatusTypes, userStateChoices } from '../user';
import { AccountTypeChoices } from './business-account-type.enum';
import { useEffect } from 'react';

const formDefaultValues={
  id:'',
  name:'',
  email:'',
  userStatus:'',
  accountNumber:'',
  accountType:'',
  kycStatus:'',
  sumsubApplicantId:'',
}

export const BusinessAccountFilterForm = () => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter
  } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  })

  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset(formDefaultValues);

  }, [form.reset])

  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter('main');
    }
  };
  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="id"
              label="Account Id"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="name"
              label="First or last name or legal name"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="email"
              label="Email"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box mr={2}>
            <SelectInput label="User status" helperText={false} source="userStatus"
                         choices={userStateChoices}/>
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="accountNumber"
              label="account Number"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <SelectInput label="Client type"
                         helperText={false}
                         source="accountType"
                         choices={AccountTypeChoices}/>
          </Box>
          <Box component="span" mr={2}>
            <SelectInput label="KYC Status"
                         helperText={false}
                         source="kycStatus"
                         choices={KycStatusTypes}/>
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="sumsubApplicantId"
              label="Sumsub Applicant ID"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2} mb={1.5}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}