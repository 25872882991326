import { EditButton, List, Pagination, TextField } from 'react-admin';
import { CustomTopToolbar } from '../top-toolbar';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

const SystemSettingsListFields =()=>{
  return [
    <TextField key="id" label="Setting id" source="id"/>,
    <TextField key="code" label="Setting code" source="code"/>,
    <TextField key="description" label="Setting description" source="description"/>,
    <TextField key="value" label="Setting value" source="value"/>,
    <EditButton key="editBtn" />,
  ]
}

export const SystemSettingsList=(props:any)=>{
  return (
    <List actions={<CustomTopToolbar />} pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>} >
      <GridComponent gridChildren={SystemSettingsListFields()} bulkActionButtons={false} rowClick="show"/>
    </List>
  )
}