import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext
} from "react-admin";
import { ReturnButton } from "../saving-plan";

export const SavingPlanTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Saving Plan: {record.id}"</span>;
}

export const SavingPlanShow = () => {
  return (
    <Show title={<SavingPlanTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="yearPercentage"/>
        <FunctionField label='Duration' render={ (record:any)=>record.duration?record.duration:'Termless'} />
        <TextField source="minimumAmount"/>
        <ReferenceField label="Asset" source="assetId" reference="asset" link='show'>
          <TextField source="shortcode"/>
        </ReferenceField>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
        <ReturnButton/>
      </SimpleShowLayout>

    </Show>
  )
}