import { Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";
import { config } from "../../config";
import { iconFields } from "../icon";

export const IconTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Icon: {record.id}"</span>;
}

export const IconShow = () => {
  const fields =[...iconFields];
  if (
    config.MODULES.INERCASH_CARD
  ) {
    fields.push(<TextField key='cardDeliverySettingId' label="Card Delivery Id" source="cardDeliverySettingId"/>);
  }

  return (
    <Show title={<IconTitle/>}>
      <SimpleShowLayout>
        {fields}
      </SimpleShowLayout>
    </Show>
  )
}