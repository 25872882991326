import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  usePermissions
} from 'react-admin';
import { transformNumberToString } from '../../@helpers/transform-number-to-string';
import { validateSavingPlanForm } from '../saving-plan';
import { useState } from 'react';
import { AdminResourceName } from '../../resource-name.enum';

export const transformFormData = ({
                         assetId,
                         yearPercentage,
                         minimumAmount,
                         duration,
                         isTermless
                       }: { assetId: string; yearPercentage: string; isTermless: boolean; duration: number; minimumAmount: string; }) => {
  const data = {
    assetId,
    yearPercentage,
    minimumAmount,
    isTermless,
  };
  if (!isTermless) {
    Object.assign(data, { duration })
  }
  return data;
}

export const SavingPlanCreate = () => {
  const { permissions } = usePermissions();
  const [isTermless, setIsTermless] = useState(false)
  return (
    <Create transform={transformFormData} redirect='show' >
      <SimpleForm validate={validateSavingPlanForm} >
        <ReferenceInput source="assetId" reference={AdminResourceName.ASSET}>
          <AutocompleteInput name="assetId" helperText={false} label="Asset" optionText={(choice:any)=>`${choice.title}: ${choice.shortcode}`} optionValue="id"
                             filterToQuery={(value: any) => ({ title: value })}/>
        </ReferenceInput>
        <NumberInput source="yearPercentage" min={0} parse={transformNumberToString}/>
        <BooleanInput source="isTermless" label='Is Flexible' defaultValue={isTermless} onChange={() => {
          setIsTermless(!isTermless)
        }}/>
        <NumberInput source="duration" min={1} disabled={isTermless} />
        <NumberInput source="minimumAmount" min={0} parse={transformNumberToString}/>
      </SimpleForm>
    </Create>
  )
}