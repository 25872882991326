import {
  DataProvider as IDataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams, HttpError
} from 'react-admin';
import axios from 'axios';
import { CreateParams, DeleteManyParams, DeleteParams, UpdateManyParams, UpdateParams } from 'ra-core/src/types';
import { AuthTokenProvider } from './auth-token.provider';
import { omitBy } from 'lodash'

const tokenProvider = new AuthTokenProvider();

const exceptionHandler = (data: any) => {
    throw new HttpError(data.message, data.statusCode);
}

const countDiff = (o1: any, o2: any) =>
  omitBy(o1, (v, k) => o2[k] === v);

export class DataProvider implements IDataProvider {

  constructor(private url: string) {
  }

  private get request() {
    const token = tokenProvider.token;
    return axios.create({
      baseURL: this.url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  sendRequest(url: string) {
    return this.request.get(url);
  }

  getOne(resource: string, params: GetOneParams) {
    // console.log(`GET: /${resource}/admin/${params.id}`)
    return this.request.get(`/${resource}/admin/${params.id}`, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  getList(resource: string, params: GetListParams) {
    return this.request.get(`/${resource}/admin`, { params })
      .then(res => {
        // console.log('res')
        // console.log(res)
        // console.log('config')
        // console.log(config)
        return res.data
      })
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  getMany(resource: string, params: GetManyParams) {
    return this.request.get(`/${resource}/admin`, { params })
      .then(res => {
        // console.log('getMany-res')
        // console.log(res)
        return res.data
      })
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  getManyReference(resource: string, params: GetManyReferenceParams) {
    return this.request.get(`/${resource}/admin`, { params })
      .then(res => {
        // console.log('getManyReference-res')
        // console.log(res)
        return res.data
      })
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  update(resource: string, params: UpdateParams) {
    // console.log(`PATCH: /${resource}/admin/${params.id}`)
    let data = countDiff(params.data, params.previousData);
    // console.log('update-data');
    // console.log(data);
    const file = Object.values(data).find((v: any) => v?.rawFile instanceof File);
    if (file) {
      data = convertToMultipart(data);
    }
    return this.request.patch(`/${resource}/admin/${params.id}`, data)
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  updateMany(resource: string, params: UpdateManyParams) {
    return this.request.patch(`/${resource}/${params.ids}`, { data: params.data })
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  create(resource: string, params: CreateParams) {
    // console.log(`POST: /${resource}/admin`)
    let data = params.data;
    console.log('create-data')
    console.log(data)
    const file = Object.values(data).find((v: any) => v.rawFile instanceof File);
    // console.log('create-file')
    // console.log(file);
    if (file) {
      data = convertToMultipart(data);
    }

    return this.request.post(`/${resource}/admin`, data)
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  };

  delete(resource: string, params: DeleteParams) {
    return this.request.delete(`/${resource}/admin/${params.id}`, {})
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }

  deleteMany(resource: string, params: DeleteManyParams) {
    return this.request.delete(`/${resource}/${params.ids}`, {})
      .then(res => res.data)
      .catch(err => {
        const data = err.response.data;
        exceptionHandler(data);
      });
  }
}

const convertToMultipart = (data: Record<string, any>) => {
  const formData = new FormData();
  for (let key in data) {
    const value = data[key];
    if (value.rawFile && value.rawFile instanceof File) {
      formData.append(key, value.rawFile, value.title);
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}
