import { Edit, SimpleForm, TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

export const NewsEdit = () => {
  return (
    <Edit>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label="News Title" name='title' source='title'/>
        <RichTextInput label="News content" source="content"/>
      </SimpleForm>
    </Edit>
  )
}
