import {
  DateField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext
} from "react-admin";

export const DocumentTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Document: {record.type}"</span>;
}

export const DocumentShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show title={<DocumentTitle/>}>
      <SimpleShowLayout>
        <TextField label="Document Id" source="id"/>
        <TextField label="Document type" source="type"/>
        <RichTextField label="Document content" source="content" sx={{fontFamily: 'Arial'}}/>
        <DateField label="Created date" source="createdAt"/>
        <DateField label="Updated date" source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}