import { Create, SimpleForm, TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

export const NewsCreate = () => {
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label='News title' name='title' source='title'/>
        <RichTextInput label='News content' source="content"/>
      </SimpleForm>
    </Create>
  )
}