export const BannerFileDescription=()=>{
  return(
    <div>
        <span>
        The image for the banner must be:
      </span>
      <ul>
        <li>
          <span>Format .jpg, .jpeg, .png;</span>
        </li>
        <li>
          <span>Maximum size 5 MB.</span>
        </li><li>
          <span>Image ration should be 16:9</span>
        </li>
      </ul>
    </div>
  )
}