import { BooleanField, DateField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin"
import { DeliveryIcon } from "./deliveryIcon";

export const DeliveryTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>"Delivery method: {record.deliveryMethod}"</span>;
}


export const IntercashDeliveryShow = () => {
  return (
    <Show title={<DeliveryTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField label="Delivery Method" source="deliveryMethod"/>
        <TextField label="Delivery Fee" source="deliveryFee"/>
        <TextField label="Delivery title" source="title"/>
        <TextField label="Delivery description" source="description"/>
        <BooleanField label="Is Enabled" source="isEnabled"/>
        <DeliveryIcon label="Icon"/>
        <DateField label="Created date" source="createdAt"/>
        <DateField label="Updated date" source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}