import { Form, useRecordContext, useRedirect, useRefresh, useUpdate } from 'react-admin';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { AdminResourceName } from '../../resource-name.enum';

export function CardSuspend(props: any) {
  const record = useRecordContext();
  const refresh = useRefresh();
  const onSuccessUpdate = () => {
    refresh();
  };
  const [showDialog, setShowDialog] = useState(false);
  const [update] = useUpdate(AdminResourceName.CARD_SUSPEND, undefined, { onSettled: onSuccessUpdate });

  if (!record) return null;

  if (record.status?.toLowerCase() === 'suspend') {
    return null;
  }

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values: any) => {
    await update(AdminResourceName.CARD_SUSPEND, {
      id: record.id,
      previousData: record,
    });

    setShowDialog(false);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <span>Suspend Card</span>
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Confirmation">
        <DialogTitle>Suspend Confirmation</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogActions>
            <Button onClick={handleCloseClick}>
              <span>Cancel</span>
            </Button>
            <Button onClick={handleSubmit}>
              <span>Ok</span>
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
