import {
  List,
  Datagrid,
  DateField,
  TextField,
  Pagination
} from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const AppListsList = () => {
  return (
    <List emptyWhileLoading pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="listName"/>
        <TextField source="listValue"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List>
  )
}