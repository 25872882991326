import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AdminResourceName } from '../../resource-name.enum';
import { TransactionStateChoices, TransactionTypes } from '../transaction';
import { useEffect } from 'react';

const ownerName = (choice: any) => {
  if (!choice) return 'No data';
  let owner;
  if (choice?.singleOwner) {
    owner = choice.singleOwner;
  } else if (choice?.users[0]?.user) {
    owner = choice.users[0].user;
  } else if (!owner) return 'No data' + Date.now();
  return (owner.profile.firstName || '') + (owner.profile.lastName || '') + ': ' + owner.email;
}

const formDefaultValues={
  id:'',
  type:'',
  state:'',
  asset:'',
  address:'',
  accountId:'',
  amountFrom:'',
  amountTo:'',
  dateFrom:'',
  dateTo:'',
}

export const TransactionFilterForm = () => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter
  } = useListContext();
  const form = useForm({
    defaultValues: filterValues,
  });
  useEffect(() => {
    // reset the entire form after component mount or form defaultValues is ready
    form.reset(formDefaultValues);
  }, [form.reset])


  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      // @ts-ignore
      setFilters(values);
    } else {
      hideFilter('main');
    }
  };

  const resetFilter = () => {
    form.reset(formDefaultValues);
    setFilters({}, []);
  };

  return (
    // @ts-ignore
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="id"
              label="Transaction Id"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <SelectInput label="Type" helperText={false} source="type" choices={TransactionTypes}/>
          </Box>
          <Box component="span" mr={2}>
            <SelectInput label="State" helperText={false} source="state" choices={TransactionStateChoices}/>
          </Box>
          <Box component="span" mr={2}>
            <ReferenceInput name="asset" source="asset" reference={AdminResourceName.ASSET}>
              <AutocompleteInput name="asset" helperText={false} label="Asset" optionText="title"
                                 optionValue="shortcode"
                                 filterToQuery={(value: any) => ({ title: value })}/>
            </ReferenceInput>
          </Box>
          <Box component="span" mr={2}>
            <TextInput
              resettable
              helperText={false}
              source="address"
              label="Address send/receive"
              InputProps={{
                endAdornment: (
                  // @ts-ignore
                  <InputAdornment position="end">
                    <SearchIcon color="disabled"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box component="span" mr={2}>
            <ReferenceInput
              name="accountId"
              reference={AdminResourceName.BUSINESS_ACCOUNT}
              source="accountId"
              allowEmpty
            >
              <AutocompleteInput
                name="accountId"
                helperText={false}
                label="User name/email"
                filterToQuery={(search: string) => ({ name: search })}
                optionText={ownerName}
                optionValue="id"
              />
            </ReferenceInput>
          </Box>
          <Box component="span" mr={2}>
            <NumberInput label="Amount From" helperText={false} name="amountFrom" source="amountFrom" defaultValue=""
                         min={0} step={0.0000001}/>
          </Box>
          <Box component="span" mr={2}>
            <NumberInput label="Amount To" helperText={false} name="amountTo" source="amountTo" defaultValue="" min={0}
                         step={0.0000001}/>
          </Box>
          <Box component="span" mr={2}>
            <DateInput label="Date From" helperText={false} name="dateFrom" source="dateFrom" defaultValue=""/>
          </Box>
          <Box component="span" mr={2}>
            <DateInput label="Date To" helperText={false} name="dateTo" source="dateTo" defaultValue=""/>
          </Box>
          <Box component="span" mr={2} mb={1.5}>
            <Button variant="outlined" color="primary" type="submit">
              Filter
            </Button>
          </Box>
          <Box component="span" mb={1.5}>
            <Button variant="outlined" onClick={resetFilter}>
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}