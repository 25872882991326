import { Create, ImageField, ImageInput, SimpleForm } from "react-admin"
import { BannerFileDescription } from "./bannerFileDescription";

export const BannerCreate = () => {
  return (
    <Create>
      <SimpleForm warnWhenUnsavedChanges>
        <ImageInput name='file' source="file" accept="image/*" maxSize={5000000}>
          <ImageField source="file" title="title"/>
        </ImageInput>
        <BannerFileDescription/>
      </SimpleForm>
    </Create>
  )
}