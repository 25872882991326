import { AssetCreate, AssetEdit, AssetList, AssetShow } from './components/asset';
import { AssetAliasCreate, AssetAliasEdit, AssetAliasList, AssetAliasShow } from './components/asset-alias';
import { RoleCreate, RoleEdit, RoleList, RoleShow } from './components/role';
import { AdminCreate, AdminEdit, AdminList, AdminShow } from './components/admin';
import { TransactionCreate, TransactionEdit, TransactionList, TransactionShow } from './components/transaction';
import { ApiLogList, ApiLogShow } from './components/api-log';
import { SavingPlanCreate, SavingPlanEdit, SavingPlanList, SavingPlanShow } from './components/saving-plan';
import { SavingList, SavingShow } from './components/saving';
import { BannerCreate, BannerEdit, BannerList, BannerShow } from './components/banner';
import { AppListsCreate, AppListsEdit, AppListsList, AppListsShow } from './components/app-lists';
import { TransactionHandlingList, TransactionHandlingShow } from './components/transaction-handling';
import { UserDeletionList } from './components/user-deletion';
import { DocumentCreate, DocumentEdit, DocumentList, DocumentShow } from './components/document';
import { NewsCreate, NewsEdit, NewsList, NewsShow } from './components/news';
import { IconCreate, IconEdit, IconList, IconShow } from './components/icon';
import {
  IntercashDeliveryCreate,
  IntercashDeliveryEdit,
  IntercashDeliveryList,
  IntercashDeliveryShow
} from './components/inercash-delivery';
import { ResourceProps } from 'ra-core/src/types';
import { BusinessAccountEdit, BusinessAccountList, BusinessAccountShow } from './components/business-account';
import BalancesReportList from './components/reports/balances-report-list.component';
import { AdminResourceName } from './resource-name.enum';
import { WalletList, WalletShow } from './components/wallets';
import { SystemSettingsEdit, SystemSettingsList, SystemSettingsShow } from './components/system-settings';
import { CardList, CardShow } from './components/cards';

export interface AdminResource extends ResourceProps {

}

export const COMMON_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.ASSET, options: { label: 'Asset List' }, list: AssetList, edit: AssetEdit, show: AssetShow,
    create: AssetCreate,
  },
  {
    name: AdminResourceName.ASSET_ALIAS,
    options: { label: 'Asset Alias List' },
    list: AssetAliasList,
    edit: AssetAliasEdit,
    show: AssetAliasShow,
    create: AssetAliasCreate,
  },
  // { name: AdminResourceName.USER, options: { label: 'Users List' }, list: UserList, edit: UserEdit, show: UserShow, },
  {
    name: AdminResourceName.BUSINESS_ACCOUNT,
    options: { label: 'Users' },
    list: BusinessAccountList,
    show: BusinessAccountShow,
    edit: BusinessAccountEdit,
  },
  {
    name: AdminResourceName.ROLE, options: { label: 'Role List' }, list: RoleList, show: RoleShow, edit: RoleEdit,
    create: RoleCreate,
  },
  {
    name: AdminResourceName.ADMIN,
    options: { label: 'Admin accounts' },
    list: AdminList,
    show: AdminShow,
    edit: AdminEdit,
    create: AdminCreate,
  },
  {
    name: AdminResourceName.TRANSACTION_ALL,
    options: { label: 'All Transactions' },
    list: TransactionList,
    show: TransactionShow,
    edit: TransactionEdit,
    create: TransactionCreate,
  },
  {
    name: AdminResourceName.API_LOG,
    options: { label: 'Api Logs' },
    list: ApiLogList,
    show: ApiLogShow,
  },
  {
    name: AdminResourceName.SAVING_PLAN,
    options: { label: 'Saving Plans' },
    list: SavingPlanList,
    show: SavingPlanShow,
    edit: SavingPlanEdit,
    create: SavingPlanCreate,
  },
  {
    name: AdminResourceName.USER_SAVING,
    options: { label: 'Savings list' },
    list: SavingList,
    show: SavingShow,
  },
  {
    name: AdminResourceName.BANNER,
    options: { label: 'Content settings' },
    list: BannerList,
    show: BannerShow,
    edit: BannerEdit,
    create: BannerCreate,
  },
  {
    name: AdminResourceName.APP_LISTS,
    options: { label: 'App lists Settings' },
    list: AppListsList,
    show: AppListsShow,
    edit: AppListsEdit,
    create: AppListsCreate,
  },
  {
    name: AdminResourceName.TRANSACTION_APPROVAL,
    options: { label: 'Transactions Pre-approval' },
    list: TransactionHandlingList,
    show: TransactionHandlingShow,
  },
  {
    name: AdminResourceName.USER_DELETION,
    options: { label: 'Requests User Deletion' },
    list: UserDeletionList,
  },
  {
    name: AdminResourceName.DOCUMENT,
    options: { label: 'Documents' },
    list: DocumentList,
    show: DocumentShow,
    edit: DocumentEdit,
    create: DocumentCreate,
  },
  {
    name: AdminResourceName.NEWS,
    options: { label: 'News' },
    list: NewsList,
    show: NewsShow,
    edit: NewsEdit,
    create: NewsCreate,
  },
  {
    name: AdminResourceName.ICON,
    options: { label: 'Icons' },
    list: IconList,
    show: IconShow,
    edit: IconEdit,
    create: IconCreate,
  },
  // {
  //   name: AdminResourceName.USER_REPORT,
  //   options: { label: 'Accounts' },
  //   list: AllUsersReportListComponent,
  // },
  {
    name: AdminResourceName.BALANCES_REPORT,
    options: { label: 'Balances Report' },
    list: BalancesReportList,
  },
  {
    name: AdminResourceName.WALLET,
    options: { label: 'Accounts (wallets)' },
    list: WalletList, show: WalletShow,
  },
  {
    name: AdminResourceName.SYSTEM_SETTINGS,
    options: { label: 'System settings' },
    list: SystemSettingsList, show: SystemSettingsShow, edit: SystemSettingsEdit,
  },
];

export const INTERCASH_RESOURCES: AdminResource[] = [
  {
    name: AdminResourceName.INTERCASH_DELIVERY,
    options: { label: 'Delivery settings' },
    list: IntercashDeliveryList,
    show: IntercashDeliveryShow,
    edit: IntercashDeliveryEdit,
    create: IntercashDeliveryCreate,
  },
  {
    name: AdminResourceName.CARD,
    options: { label: 'Cards' },
    list: CardList,
    show: CardShow,
  },
];
