import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { WalletBalance } from './wallet-balance';
import { fullName, getProviderNumber } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';

export const WalletTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Account (wallet): {record.id}"</span>;
}


export const WalletShow =()=>{
  return (
    <Show title={<WalletTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <WalletBalance label='balance'/>
        <TextField source="asset"/>
        <FunctionField label="Provider number" render={(record: any) =>
          getProviderNumber(record)
        }/>
        <ReferenceField label="account" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
          <TextField source="accountNumber"/>
        </ReferenceField>
        <ReferenceField key="ownerEmail" label="Email" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                        link="show">
          <FunctionField render={(record: any) => owner(record)?.email}/>
        </ReferenceField>
        <ReferenceField key="ownerName" label="Client's name" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT}
                        link="show">
          <FunctionField render={(record: any) => fullName(owner(record))}
          />
        </ReferenceField>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}