import { Box } from '@mui/material';
import {
  List,
  DateField,
  ReferenceField,
  TextField,
  TopToolbar,
  ShowButton,
  FunctionField,
  usePermissions,
  Pagination,
  BooleanField,
  ExportButton,
  Exporter,
} from 'react-admin';
import { TransactionFilterButton } from '../transaction';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { AdminResourceName } from '../../resource-name.enum';
import { CardFilterForm } from './card-filter-form';
import { fullName } from '../../@helpers/wallet.helpers';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { owner } from '../../@helpers/account.helpers';
import { GridComponent } from '../grid/grid-component';

const customExporter: Exporter = (data) => {
  const dataForExport = data.map((card: any) => {
    

    const cardToExport = {
      "ID": card.id,
      "Card Number": card.cardNumber,
      "SAN": card.referenceId,
      "Account number": card.account.accountNumber,
      "Clients email": card.user.email,
      "Clients name": fullName(card.user),
      "Proof Of Residence Uploaded document status": card.proofOfResidenceUploaded,
      "Proof Of Identity Uploaded document status": card.proofOfIdentityUploaded,
      "Created at": card.createdAt,
    }

    return cardToExport;
  });

  jsonExport(dataForExport, (err: any, csv: any) => {
    if (err) {
      return;
    }

    downloadCSV(csv, `Neobanking Cards data ${new Date().toLocaleString()}`);
  }); 
}

const ListActions = (props: any) => {
  return (
    <Box width="100%">
      <TopToolbar>
        <TransactionFilterButton />
        <ExportButton exporter={customExporter} />
      </TopToolbar>
      {props.form}
    </Box>
  );
};

const CardsListFields = () => {
  return [
    <TextField key="id" source="id" />,
    <TextField key="cardNumber" source="cardNumber" />,
    <TextField key="SAN" source="referenceId" label="SAN" />,
    <ReferenceField
      key="AccountNumber"
      label="Account number"
      source="accountId"
      reference={AdminResourceName.BUSINESS_ACCOUNT}
      link="show"
    >
      <TextField source="accountNumber" />
    </ReferenceField>,
    <ReferenceField
      key="ownerEmail"
      label="Client's email"
      source="accountId"
      reference={AdminResourceName.BUSINESS_ACCOUNT}
      link="show"
    >
      <FunctionField render={(record: any) => owner(record)?.email} />
    </ReferenceField>,
    <ReferenceField
      key="ownerName"
      label="Client's name"
      source="accountId"
      reference={AdminResourceName.BUSINESS_ACCOUNT}
      link="show"
    >
      <FunctionField render={(record: any) => fullName(owner(record))} />
    </ReferenceField>,
    <BooleanField key="proofOfResidenceUploaded" source="proofOfResidenceUploaded" />,
    <BooleanField key="proofOfIdentityUploaded" source="proofOfIdentityUploaded" />,
    <DateField key="createdAt" source="createdAt" />,
    <ShowButton key="showBtn" />,
  ];
};

export const CardList = () => {
  const { isLoading } = usePermissions();

  if (isLoading) return null;

  return (
    <List
      actions={<ListActions form={<CardFilterForm />} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
    >
      <GridComponent bulkActionButtons={false} gridChildren={CardsListFields()} />
    </List>
  );
};
