import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { fullName } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';
import { RoleAction, RolePermissions } from '../role';
import { CardSuspend } from './card-suspend';
import { Box, Typography } from '@mui/material';

export const CardTitle = () => {
  const record = useRecordContext();

  if (!record) return null;

  return <span>"Card: {record.id}"</span>;
};

export const EmptyCardTransactionsList = () => (
  <Box textAlign="center" m={1}>
    <Typography variant="h5" paragraph>
      No card transactions found
    </Typography>
  </Box>
);

export const CardTransactions = (props: any) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <List
      exporter={false}
      pagination={false}
      resource={`${AdminResourceName.CARD_TRANSACTIONS}/${record.id}`}
      sort={{ field: 'Date', order: 'DESC' }}
    >
      <Datagrid title="Card Transactions" bulkActionButtons={false}>
        <TextField sortable={false} key="Date" source="Date" />,
        <TextField sortable={false} key="Amount" source="Amount" />,
        <TextField sortable={false} key="Currency" source="Currency" />,
        <TextField sortable={false} key="Desciption" source="Desciption" />,
      </Datagrid>
    </List>
  );
};

export const CardShow = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return null;

  const handlePermission = RolePermissions[permissions].find(
    ({ action, resource }) => resource === AdminResourceName.ALL && action === RoleAction.ALL,
  );

  return (
    <Show title={<CardTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="asset" />
        <TextField source="cardNumber" />
        <TextField source="provider" />
        <TextField source="status" />
        <TextField source="type" />
        <TextField key="SAN" source="referenceId" label="SAN"/>,

        <ReferenceField label="account" source="accountId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
          <TextField source="accountNumber" />
        </ReferenceField>
        <ReferenceField
          key="ownerEmail"
          label="Client's email"
          source="accountId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <FunctionField render={(record: any) => owner(record)?.email} />
        </ReferenceField>
        <ReferenceField
          key="ownerName"
          label="Client's name"
          source="accountId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <FunctionField render={(record: any) => fullName(owner(record))} />
        </ReferenceField>

        <BooleanField source="proofOfResidenceUploaded" />
        <BooleanField source="proofOfIdentityUploaded" />

        <DateField source="createdAt" />
        {handlePermission && <CardSuspend />}
        {/* <CardTransactions /> */}
      </SimpleShowLayout>
    </Show>
  );
};
