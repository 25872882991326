import { Edit, SimpleForm, TextInput } from 'react-admin';
import { CustomToolbar } from '../toolbar';
import { ValidateSystemCodeValue } from './domain/validate-system-code-value';

const validateSystemSettingsEdit = (values: any) => {
  // @ts-ignore
  const result = ValidateSystemCodeValue[values.code]('value', values.value);
  return { ...result };
}

const transformSystemSettingsEdit = (data: any): any => {
  return {
    [data.code]: data.value
  };
}

export const SystemSettingsEdit = (props: any) => {
  return (
    <Edit
      transform={transformSystemSettingsEdit}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar saveButton={true}/>}
                  validate={validateSystemSettingsEdit}>
        <TextInput label="Setting id" source="id" disabled/>
        <TextInput label="Setting code" source="code" disabled/>
        <TextInput label="Setting description" source="description" disabled/>
        <TextInput label="Setting value" source="value"/>
      </SimpleForm>
    </Edit>
  );
}