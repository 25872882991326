import { choicesFromEnum } from "../../@helpers/choices-from-enum";
import { AdminResourceName } from "../../resource-name.enum";

export enum AdminRole {
  SUPER_ADMIN = 'Super_admin',
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  TRANSACTION_OFFICER = 'Transactions_Officer',
  VIEW_ONLY = 'View_only',
}

export enum RoleAction {
  READ = 'read',
  EDIT = 'edit',
  CREATE = 'create',
  ALL='all',
}

export const AdminRolesChoices = choicesFromEnum(AdminRole).map(({ id, name }) => {
  return { id: id, name: name.replace('_', ' ') }
});

export interface IResourcePermission {
  action: RoleAction | RoleAction[];
  resource: AdminResourceName;
}

export interface IRolePermissions {
  [k: string]: IResourcePermission[]
}

export const RolePermissions: IRolePermissions = {
  [AdminRole.SUPER_ADMIN]: [{ action: RoleAction.ALL, resource: AdminResourceName.ALL }],
  [AdminRole.ADMIN]: [],
  [AdminRole.MANAGER]: [],
  [AdminRole.TRANSACTION_OFFICER]: [
    {
      action: RoleAction.READ,
      resource: AdminResourceName.BUSINESS_ACCOUNT,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.WALLET,
    },
    {
      action:RoleAction.CREATE,
      resource: AdminResourceName.TRANSACTION_ALL,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.TRANSACTION_APPROVAL,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.SAVING_PLAN,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.USER_SAVING,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.BANNER,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.USER_DELETION,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.DOCUMENT,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.NEWS,
    },
    {
      action: RoleAction.CREATE,
      resource: AdminResourceName.CREATE_ACCRUAL_TRANSACTION,
    },
    {
      action: RoleAction.EDIT,
      resource: AdminResourceName.HANDLE_APPROVAL_TRANSACTION,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.HANDLE_USER_DELETION,
    },
  ],
  [AdminRole.VIEW_ONLY]: [
    {
      action: RoleAction.READ,
      resource: AdminResourceName.BUSINESS_ACCOUNT,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.WALLET,
    },
    {
      action:RoleAction.READ,
      resource: AdminResourceName.TRANSACTION_ALL,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.TRANSACTION_APPROVAL,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.SAVING_PLAN,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.USER_SAVING,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.BANNER,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.USER_DELETION,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.DOCUMENT,
    },
    {
      action: RoleAction.READ,
      resource: AdminResourceName.NEWS,
    },
  ],
}