import { TextField, useGetOne, useRecordContext } from "react-admin";
import { AdminResourceName } from '../../resource-name.enum';

export const CountryShow = (props: any) => {
  const record = useRecordContext();
  // console.log('CountryShow=record')
  // console.log(record)
  const { data, isLoading, error } = useGetOne(
    AdminResourceName.COUNTRY,
    { id: record.profile.countryIsoCode }
  )
  if (isLoading || error) return null;
  return (<TextField label="Country" record={data} source="name"/>)
}

export const CountryShowByRecord = (props: any) => {
  const record = props.record;
  // console.log('CountryShow=record')
  // console.log(record)
  const { data, isLoading, error } = useGetOne(
    AdminResourceName.COUNTRY,
    { id: record.profile.countryIsoCode }
  )
  if (isLoading || error) return null;
  return (<TextField label="Country" record={data} source="name"/>)
}