import { createElement } from 'react';
import { Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';

export const CustomMenu = (props: any) => {
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  return (
    <Menu {...props}>
      {Object.values(resources).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={{
            pathname: resource.name,
            search: 'filter=%7B%7D',
          }}
          primaryText={resource.options?.label || resource.name}
          leftIcon={resource.icon ? createElement(resource.icon) : <DefaultIcon/>}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {/*<SubMenu primaryText="Reports" leftIcon={<SummarizeIcon/>}>*/}
      {/*    {REPORT_RESOURCES.map(resource => (*/}
      {/*            <MenuItemLink*/}
      {/*                key={resource.name}*/}
      {/*                to={`/${resource.name}`}*/}
      {/*                primaryText={resource.options?.label || resource.name}*/}
      {/*                leftIcon={*/}
      {/*                    resource.icon ? createElement(resource.icon) : <DefaultIcon/>*/}
      {/*                }*/}
      {/*                onClick={props.onMenuClick}*/}
      {/*                sidebarIsOpen={open}*/}
      {/*            />*/}
      {/*        )*/}
      {/*    )}*/}
      {/*</SubMenu>*/}
    </Menu>
  );
};
