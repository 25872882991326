import {
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput, SaveButton,
  SelectInput,
  SimpleForm,
  TextInput, Toolbar,
  useEditContext,
  usePermissions
} from 'react-admin';
import { CountrySelect } from '../country/countrySelect';
import { InternalKycStatusEditChoices, KycStatusTypes, userStateChoices, } from '../user';
import { BusinessAccountTitle, RefCurrencyChoices, OrganisationUserRoleChoices } from '../business-account';
import { AdminRole } from '../role';
import { diffFromPrevious } from '../../@helpers/diff-from-previous';
import { AdminResourceName } from '../../resource-name.enum';
import { BusinessListName } from '../app-lists';
import { replaceNullByEmptyValue } from '../../@helpers/sanitize-data';

const AccountEditToolbar = (props: any) => {
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton/>
    </Toolbar>
  )
}

export const AccountWithUserEditForm = () => {
  const { record, error, isLoading, } = useEditContext();
  const { permissions } = usePermissions();
  if (!record || isLoading) return (<div>...Loading</div>)
  // console.log('record', record);
  if (error) return (<div>{error}</div>);
  const { users, singleOwner, ...data } = record;
  if (singleOwner) {
    data['owner'] = singleOwner;
  } else {
    data['owner'] = users[0].user;
    data['owner'].userRole = users[0].userRole;
  }
  const entity = data['owner']?.userRole ? 'Company' : 'User';
  const replacedData = replaceNullByEmptyValue(data);
  if(!replacedData.internalKycStatus){
    replacedData.internalKycStatus='DISABLED_INTERNAL'
  }
  // console.log('replacedDate',replacedData);
  return (<SimpleForm record={replacedData} warnWhenUnsavedChanges toolbar={<AccountEditToolbar/>}>
    <TextInput name="id" disabled source="id" defaultValue=""/>
    <TextInput name="accountNumber" disabled source="accountNumber" defaultValue=""/>
    <TextInput label={`${entity} legal name`} name="legalName" source="legalName" defaultValue=""/>
    <TextInput label="User first name" name="owner.profile.firstName" source="owner.profile.firstName" defaultValue=""/>
    <TextInput label="User last Name" name="owner.profile.lastName" source="owner.profile.lastName" defaultValue=""/>
    <DateInput label="User Birthday" name="owner.profile.birthDate" source="owner.profile.birthDate" defaultValue=""/>
    <SelectInput label="KYC status" name="kycStatus" source="kycStatus" optionText="name" choices={KycStatusTypes}
                 disabled/>
    <SelectInput label="internal KYC status" name="internalKycStatus" source="internalKycStatus" optionText="name" choices={InternalKycStatusEditChoices}
                 emptyText="DISABLED_INTERNAL" emptyValue="DISABLED_INTERNAL" defaultValue="DISABLED_INTERNAL"/>
    <CountrySelect name="owner.profile.countryIsoCode" label="User country"
                   source="owner.profile.countryIsoCode" defaultValue=""/>
    <SelectInput label="User reference currency" name="owner.profile.currency" source="owner.profile.currency"
                 choices={RefCurrencyChoices} defaultValue=""/>
    <TextInput label="User City" name="owner.profile.city" source="owner.profile.city" defaultValue=""/>
    <TextInput label="User Address Line 1" name="owner.profile.addressLine1" source="owner.profile.addressLine1" defaultValue=""/>
    <TextInput label="User Address Line 2" name="owner.profile.addressLine2" source="owner.profile.addressLine2" defaultValue=""/>
    <TextInput label="User Postal Code" name="owner.profile.postalCode" source="owner.profile.postalCode" defaultValue=""/>
    {permissions === AdminRole.SUPER_ADMIN &&
    <BooleanInput label="User two factor auth state" name="owner.isTwoFactorAuthEnabled"
                  source="owner.isTwoFactorAuthEnabled"
                  disabled={!data?.owner?.isTwoFactorAuthEnabled}/>
    }
    <SelectInput
      name="owner.status"
      label="User action status"
      source="owner.status"
      choices={userStateChoices}
      optionText="name"
      defaultValue=""
    />
    {entity === 'Company' && <ReferenceInput reference={AdminResourceName.APP_LISTS} source="businessCategoryId"
                                             filter={{ listName: BusinessListName.businessCategory }}>
        <SelectInput name="businessCategoryId"
                     label="Business category"
                     optionText="listValue" optionValue="id" defaultValue=""/>
    </ReferenceInput>}
    {entity === 'Company' && <ReferenceInput reference={AdminResourceName.APP_LISTS} source="businessTypeId"
                                             filter={{ listName: BusinessListName.businessType }}>
        <SelectInput name="businessTypeId"
                     label="Business type"
                     optionText="listValue"
                     optionValue="id"
                     defaultValue=""/>
    </ReferenceInput>}
    <CountrySelect name="legalCountryCode" label={`${entity} legal country`}
                   source="legalCountryCode" defaultValue=""/>
    {entity === 'Company' && <SelectInput label="User role in company" name="owner.userRole" source="owner.userRole"
                                          choices={OrganisationUserRoleChoices} optionText="name"/>}
    {entity === 'Company' &&
    <TextInput label="Authorised personal email" name="owner.profile.email" source="owner.profile.email"
               defaultValue=""/>}
    <TextInput label={`${entity} legal city`} source="legalCity" defaultValue=""/>
    <TextInput label={`${entity} legal address`} source="legalAddress" defaultValue=""/>
    <TextInput label={`${entity} postal code`} source="postalCode" defaultValue=""/>
    {entity === 'Company' &&
    <TextInput label="Company Url" source="companyUrl" disabled={!!record.singleOwner} defaultValue=""/>}
    <TextInput label={`${entity} phone`} source="phone" defaultValue=""/>
    {entity === 'Company' &&
    <TextInput label="Company registration number" source="registrationNumber" defaultValue=""/>}
    {entity === 'Company' && <DateInput label="Company formation date" source="formationDate" defaultValue=""/>}
  </SimpleForm>)
}

export const BusinessAccountEdit = () => {

  const transform = (data: any, options: any): any => {
    const { users: u, singleOwner: s, ...account } = data;
    const { users, singleOwner, ...previousAccount } = options.previousData;
    if (singleOwner) {
      previousAccount['owner'] = singleOwner;
    } else {
      previousAccount['owner'] = users[0].user;
    }
    // console.log('account', account)
    const diff = diffFromPrevious(account, previousAccount)
    // console.log('diffFromPrevious(account,previousData)', diff);
    if (diff['owner']) {
      diff['owner'].id = previousAccount['owner'].id;
    }
    return diff;
  }

  return (
    <Edit title={<BusinessAccountTitle/>} transform={transform} redirect={'show'}>
      <AccountWithUserEditForm/>
    </Edit>

  )
}