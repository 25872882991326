import { DateField, Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";

export const AppListTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>List "{record.listName}"</span>;
}

export const AppListsShow = () => {
  return (
    <Show title={<AppListTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="listName"/>
        <TextField source="listValue"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </SimpleShowLayout>
    </Show>
  )
}