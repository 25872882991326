import { choicesFromEnum } from '../../@helpers/choices-from-enum';

enum TransactionType {
  transfer = 'TRANSFER',
  exchange = 'EXCHANGE',
  saving = 'SAVING',
  topUp = 'TOP_UP',
  fee='FEE',
}

const TransactionTypes = choicesFromEnum(TransactionType);

export { TransactionTypes, TransactionType }