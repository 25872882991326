export enum DocumentType {
  termsAndConditions = 'Terms & Conditions',
  privacyPolicy = 'Privacy Policy',
  savingsTermsConditions = 'Savings Terms & Conditions',
  feeSchedule = 'Fee Schedule',
}


export const DocumentTypeChoices = Object.values(DocumentType).map(
  (value) => {
    return {
      id: value,
      name: value,
    }
  }
);