import { Form, SelectInput, TextInput, useRecordContext, useRedirect, useUpdate } from "react-admin";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TransactionSubStatuses } from "./transaction-sub-status.enum";
import { SubmitButton } from "../user";
import { AdminResourceName } from "../../resource-name.enum";

export function HandleTransaction(props: any) {
  const record = useRecordContext();
  const redirect = useRedirect()
  const [showDialog, setShowDialog] = useState(false);
  const [update] = useUpdate(AdminResourceName.TRANSACTION_APPROVAL, undefined, { onSuccess: (data) => redirect('show', AdminResourceName.TRANSACTION_ALL, record.id, data) });
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values: any) => {
    // console.log('handleSubmit=>values')
    // console.log(values)
    const dataToUpdate = {
      payload: {
        txSubStatus: values.payload.txSubStatus,
        decisionReason: values.payload.decisionReason
      }
    }
    await update(
      AdminResourceName.TRANSACTION_APPROVAL,
      {
        id: record.id,
        data: dataToUpdate,
        previousData: record,
      }
    );
    setShowDialog(false);


  }

  return (<>
    <Button onClick={handleClick}>
      <span>Make Decision</span>
    </Button>
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Handle Transaction"
    >
      <DialogTitle>Update Transaction</DialogTitle>
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <SelectInput label="New Status" helperText={false} source="payload.txSubStatus" name="payload.txSubStatus"
                       choices={TransactionSubStatuses}/>
          <TextInput
            label="Decision reason"
            source="payload.decisionReason"
            name="payload.decisionReason"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseClick}
          >
            <span>Cancel</span>
          </Button>
          <SubmitButton>Save</SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  </>);
}