import { isPositiveNumberString } from '../../../@helpers/is-positive-number-string.helper';

function positiveNumberString(keyName: string, value: string, errorMessage = 'The Fee value must be a positive number'): object {
  return (!isPositiveNumberString(value) && { [keyName]: errorMessage }) || {}
}

function anyData(name: string, value: string, message?: string): object {
  return {}
}

export const ValidateSystemCodeValue = {
  CARD_DELIVERY_COUNTRY: anyData,
  CARD_DELIVERY_STATE: anyData,
  CARD_DELIVERY_CITY: anyData,
  CARD_DELIVERY_POSTAL_CODE: anyData,
  CARD_DELIVERY_ADDRESS_LINE_1: anyData,
  CARD_DELIVERY_ADDRESS_LINE_2: anyData,
  CARD_FEE_ISSUE: positiveNumberString,
  EXCHANGE_FEE: positiveNumberString,
  EXCHANGE_RATE_GAP: positiveNumberString,
  TRANSFER_CRYPTO_SEND_FEE: positiveNumberString,
  TRANSFER_CRYPTO_RECEIVE_FEE: positiveNumberString,
  ACCOUNT_MAINTENANCE_FEE: positiveNumberString,
}