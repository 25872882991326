import { Datagrid, DateField, ImageField, List, Pagination, TextField } from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const BannerList = () => {
  return (
    <List sort={{ field: 'visualisationPosition', order: 'DESC' }} debounce={0}
          pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="Visualization position" source="visualisationPosition"/>
        <TextField source="id"/>
        <ImageField source="bannerUrl" title="banner"/>
        <TextField source="bannerName"/>
        <TextField source="fileName"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List>
  )
}