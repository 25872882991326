import { List, Datagrid, TextField, DateField, ReferenceField, FunctionField, Pagination } from 'react-admin';
import { AdminResourceName } from '../../resource-name.enum';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const SavingPlanList = () => {
  return (
    <List pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="yearPercentage" />
        <FunctionField label="Duration" render={(record: any) => (record.duration ? record.duration : 'Flexible')} />
        <TextField source="minimumAmount" />
        <ReferenceField label="Asset" source="assetId" reference={AdminResourceName.ASSET} link="show">
          <TextField source="shortcode" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
};
