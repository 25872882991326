import {
  BooleanField,
  DateField,
  FunctionField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext, useRedirect
} from 'react-admin';
import { AccountTotalBalance, IdTypeEnum } from '../balance';
import { OrganizationName } from './business-account-list';
import { fullName } from '../../@helpers/wallet.helpers';
import { AdminRole } from '../role';
import { CountryShowByRecord } from '../country';
import { Button } from '@mui/material';
import { AdminResourceName } from '../../resource-name.enum';
import { AccountType, owner } from '../../@helpers/account.helpers';
import { InternalKycStatusEditChoices } from '../user';
import { uriStringify } from '../../@helpers/uri-stringify';

export const BusinessAccountTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Account "{record.legalName}" :: accountNumber "{record.accountNumber}"</span>;
};

export const SeeWalletsButton = (props: any) => {
  const redirect = useRedirect();
  const record = props.record
  if (!record) return null;
  const handleSeeAccountsClick = () => {
    redirect(`/${AdminResourceName.WALLET}?filter=${uriStringify({ accountId: record.id })}`);
  }
  return (<Button onClick={handleSeeAccountsClick} children={<span>See accounts</span>}/>)
}


export const BusinessAccountShow = () => {
  const { permissions } = usePermissions()

  return (
    <Show title={<BusinessAccountTitle/>}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="accountNumber"/>
        <FunctionField label="Account Type" render={(record: any) => AccountType(record)}/>
        <AccountTotalBalance label="Balance" idType={IdTypeEnum.BUSINESS_ACCOUNT}/>
        <FunctionField label="Account Email" render={(record: any) => owner(record)?.email}/>
        <FunctionField label="Legal name" render={(record: any) => <OrganizationName record={record}/>}/>
        <FunctionField label="User Name" render={(record: any) => fullName(owner(record))}/>
        <FunctionField label="User Email" render={(record: any) => owner(record)?.profile?.email}/>
        <FunctionField label="User status" render={(record: any) => owner(record)?.status}/>
        <FunctionField label="User role" render={(record: any) => record?.users[0]?.userRole}/>
        {
          permissions === AdminRole.SUPER_ADMIN &&
          <FunctionField label="isTwoFactorAuthEnabled" render={(record: any) => <BooleanField record={owner(record)}
                                                                                               source="isTwoFactorAuthEnabled"/>}/>
        }
        <FunctionField label="User Birthday" render={(record: any) => <DateField label="Birthday" record={owner(record)}
                                                                                 source="profile.birthDate"/>}/>
        <FunctionField label="User Country" render={(record: any) => <CountryShowByRecord record={owner(record)}/>}/>
        <FunctionField label="User City" render={(record: any) => owner(record)?.profile?.city}/>
        <FunctionField label="User Address" render={(record: any) => owner(record)?.profile?.addressLine1 + ', ' + owner(record)?.profile?.addressLine2}/>
        <FunctionField label="User Postal Code" render={(record: any) => owner(record)?.profile?.postalCode}/>
        <FunctionField label="User Reference currency"
                       render={(record: any) => <TextField record={owner(record)} source="profile.currency"/>}/>
        {
          permissions === AdminRole.SUPER_ADMIN &&
          <FunctionField label="User deletion Requested"
                         render={(record: any) => <DateField record={owner(record)} source="deletionRequestedAt"/>}/>
        }
        {
          permissions === AdminRole.SUPER_ADMIN &&
          <FunctionField label="User deletion state"
                         render={(record: any) => owner(record)?.deletedAt ? 'Deactivated' : 'Active'}/>
        }
        {
          permissions === AdminRole.SUPER_ADMIN &&
          <FunctionField label="User deactivated date" render={(record: any) =>  <DateField record={owner(record)} source="deletedAt"/>}/>
        }
        <TextField source="legalCountryCode"/>
        <TextField source="legalCity"/>
        <TextField source="legalAddress"/>
        <TextField source="postalCode"/>
        <TextField source="companyUrl"/>
        <TextField source="phone"/>
        <TextField source="registrationNumber"/>
        <DateField source="formationDate"/>
        <SelectField source="internalKycStatus" choices={InternalKycStatusEditChoices} emptyText="DISABLED_INTERNAL"/>
        <TextField source="kycStatus"/>
        <TextField source="sumsubApplicantId"/>
        <TextField source="fireblocksVaultId"/>
        <TextField source="intId"/>
        <ReferenceField label="BusinessType" source="businessTypeId" reference="app_lists">
          <TextField source="listValue"/>
        </ReferenceField>
        <ReferenceField label="BusinessCategory" source="businessCategoryId" reference="app_lists">
          <TextField source="listValue"/>
        </ReferenceField>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
        <FunctionField render={(record: any) => <SeeWalletsButton record={record}/>}/>
      </SimpleShowLayout>
    </Show>
  )
}