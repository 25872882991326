import { Datagrid, EditButton, List, Pagination, TextField } from 'react-admin';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const RoleList = () => {
  return (
    <List pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField source="roleType"/>
        <TextField source="description" defaultValue=""/>
        <TextField source="createdAt"/>
        <TextField source="updatedAt"/>
        <EditButton/>
      </Datagrid>
    </List>
  )
}