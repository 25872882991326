import { DateField, List, Pagination, TextField } from 'react-admin';
import { config } from "../../config";
import { ShowContent } from './show-content';
import { CustomTopToolbar } from '../top-toolbar';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

export const iconFields =[
  <TextField key='id' source="id"/>,
  <TextField key='type' label="Type" source="type"/>,
  <ShowContent key='content' label="Content"/>,
  <DateField key='createdAt' label="Created date" source="createdAt"/>,
  <DateField key='updatedAt' label="Updated date" source="updatedAt"/>,
];
export const iconListFields =()=>{
  const fields=[...iconFields];
  if (
    config.MODULES.INERCASH_CARD
  ) {
    fields.push(<TextField key='cardDeliverySettingId' label="Card Delivery Id" source="cardDeliverySettingId"/>);
  }
  return fields
}

export const IconList=()=>{
  return(
    <List actions={<CustomTopToolbar />} pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent gridChildren={iconListFields()} rowClick="show" bulkActionButtons={false}/>
    </List>
  )
}