import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { senderName, TransactionAddressField, TransactionTitle, TransactionType } from '../transaction';
import { FileData } from '../user-file';
import { HandleTransaction } from './handle-transaction';
import { RoleAction, RolePermissions } from '../role';
import { AdminResourceName } from '../../resource-name.enum';
import { ITransactionModel } from '../transaction/domain/transaction.model';

export const PayeeCountry = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (record.sendAsset === 'EUR') {
      return <TextField label="Payee Country" source="payload.order.payee.individual.address.country" />;
    }

    if (record.sendAsset === 'USD') {
      return <TextField label="Payee Country" source="payload.order.country" />;
    }
  }

  return null;
};

export const PayeeCity = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (record.sendAsset === 'EUR') {
      return <TextField label="Payee City" source="payload.order.payee.individual.address.city" />;
    }


    if (record.sendAsset === 'USD') {
      return <TextField label="Payee City" source="payload.order.city" />;
    }
  }

  return null;
};

export const PayeeAddress = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (record.sendAsset === 'EUR') {
      return <TextField label="Payee Street" source="payload.order.payee.individual.address.street" />;
    }

    if (record.sendAsset === 'USD') {
      return <TextField label="Payee Street" source="payload.order.address" />;
    }
  }

  return null;
};

export const PayeeZipCode = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    
    if (record.sendAsset === 'EUR') {
      return <TextField label="Payee Zip code" source="payload.order.payee.individual.address.zip" />;
    }

    if (record.sendAsset === 'USD') {
      return <TextField label="Payee Zip code" source="payload.order.postalCode" />;
    }
  }

  return null;
};

export const TransactionHandlingShow = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return null;

  const handleTransactionPermission = RolePermissions[permissions].find(({ action, resource }) => {
    return (
      (resource === AdminResourceName.HANDLE_APPROVAL_TRANSACTION && action === RoleAction.EDIT) ||
      (resource === AdminResourceName.ALL && (action === RoleAction.EDIT || action === RoleAction.ALL))
    );
  });

  return (
    <Show title={<TransactionTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField label="Amount" source="payload.order.amount" />
        <TextField label="Currency" source="receivedAsset" />
        
        <FunctionField label="Sender name" render={(record: ITransactionModel) => senderName(record)} />
        <TransactionAddressField key="source" label="Sender account" sortBy="source" source="source" />
        <ReferenceField
          key="SenderAccountNumber"
          label="Sender account number"
          source="senderId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <TextField source="accountNumber" />
        </ReferenceField>

        <TextField label="Receiver name" source="beneficiary" />
        <TransactionAddressField key="destination" label="Destination" sortBy="destination" source="destination"/>
        <ReferenceField
          key="ReceiverAccountNumber"
          label="Receiver account number"
          source="receiverId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <TextField source="accountNumber" />
        </ReferenceField>
        
        <TextField source="reference" />
        <TextField label="Payer IBAN" source="payload.order.payerRequisite.iban" />
        <TextField label="Payer SWIFT code" source="payload.order.payerRequisite.bankSwiftCode" />
        <TextField label="Payee IBAN" source="payload.order.payeeRequisite.iban" />
        <TextField label="Payee SWIFT code" source="payload.order.payeeRequisite.bankSwiftCode" />

        <PayeeCountry label="Payee country" />
        <PayeeCity label="Payee city" />
        <PayeeAddress label="Payee address" />
        <PayeeZipCode label="Payee zip code" />

        <TextField source="fee" />
        <TextField source="state" />
        <TextField source="type" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <FileData label="Appended File" />
        {handleTransactionPermission && <HandleTransaction />}
      </SimpleShowLayout>
    </Show>
  );
};
