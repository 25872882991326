import { Datagrid, DateField, List, Pagination, TextField } from 'react-admin';
import { SimpleContentView } from "../document";
import { ROWS_PER_PAGE } from '../pagination/pagination.const';

export const NewsList = () => {
  return (
    <List sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id"/>
        <TextField label="Title" source="title"/>
        <SimpleContentView label="News content" source="content"/>
        <DateField label="Created date" source="createdAt"/>
        <DateField label="Updated date" source="updatedAt"/>
      </Datagrid>
    </List>
  )
}
