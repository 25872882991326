export enum AdminResourceName {
  ALL = 'all',
  ASSET = 'asset',
  ASSET_ALIAS = 'asset_alias',
  USER = 'user',
  BUSINESS_ACCOUNT = 'business_account',
  ROLE = 'role',
  ADMIN = 'admin',
  TRANSACTION_ALL = 'transaction/all',
  TRANSACTION_APPROVAL = 'transaction/approval',
  API_LOG = 'api_log',
  SAVING_PLAN = 'saving_plan',
  USER_SAVING = 'user_saving',
  BANNER = 'banner',
  APP_LISTS = 'app_lists',
  USER_DELETION = 'user_deletion',
  DOCUMENT = 'document',
  NEWS = 'news',
  ICON = 'icon',
  INTERCASH_DELIVERY = 'intercash_delivery',
  USER_REPORT = 'users_report',
  BALANCES_REPORT = 'balances_report',
  USER_FILE = 'user_file',
  WALLET = 'wallet',
  CARD = 'card',
  CARD_SUSPEND = 'card/suspend',
  CARD_TRANSACTIONS = 'card/transactions',
  BALANCE = 'balance',
  COUNTRY = 'country',
  SYSTEM_SETTINGS = 'system_settings',
  //sub-resources
  CREATE_ACCRUAL_TRANSACTION = 'create_accrual_transaction',
  HANDLE_APPROVAL_TRANSACTION = 'handle_transaction',
  HANDLE_USER_DELETION = 'handle_user_deletion',
}
