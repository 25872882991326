import {
  CreateButton,
  DateField,
  ExportButton,
  List,
  ReferenceField,
  TextField,
  TopToolbar,
  EditButton,
  usePermissions,
  FunctionField,
  useRedirect,
  ShowButton,
  Pagination,
  useRecordContext,
  useGetOne,
} from 'react-admin';
import { Box, Button } from '@mui/material';
import { TransactionFilterButton, TransactionFilterForm } from '../transaction';
import { AdminResourceName } from '../../resource-name.enum';
import { RoleAction, RolePermissions } from '../role';
import { FiatTransactionType, ITransactionModel } from './domain/transaction.model';
import { fullName, getProviderNumber } from '../../@helpers/wallet.helpers';
import { owner } from '../../@helpers/account.helpers';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';

export function senderName(record: ITransactionModel) {
  const { senderId, payload } = record;
  if (senderId) {
    return (<ReferenceField label="Sender" source="senderId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
      <FunctionField render={(record: any) => fullName(owner(record))}/>
    </ReferenceField>)
  }
  if (payload && payload?.order?.transactionType === FiatTransactionType.Payin) {
    return payload?.order?.paymentDetails?.payerRequisite?.name ?
      <TextField label="Sender" record={payload} source="order.paymentDetails.payerRequisite.name"/> : null;
  }
  return null
}

export function receiverName(record: ITransactionModel) {
  const { receiverId, payload } = record;
  if (receiverId) {
    return (
      <ReferenceField label="Receiver" source="receiverId" reference={AdminResourceName.BUSINESS_ACCOUNT} link="show">
        <FunctionField render={(record: any) => fullName(owner(record))}/>
      </ReferenceField>)
  }
  if (payload && payload?.order?.transactionType === FiatTransactionType.Payout) {
    return payload?.order?.payee?.individual?.firstName ? <TextField label="Receiver"
                                                                     record={{ name: [payload?.order?.payee?.individual?.firstName, payload?.order?.payee?.individual?.lastName].join(' ') }}
                                                                     source="name"/> : null;
  }
  return null
}

const ListActions = (props: any) => (
  <Box width="100%">
    <TopToolbar>
      <TransactionFilterButton/>
      {props.createTransaction && <CreateButton/>}
      <ExportButton/>
    </TopToolbar>
    <TransactionFilterForm/>
  </Box>
);

const DataFromWalletField = (props: any) => {
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(AdminResourceName.WALLET, {
    id: props.source,
    meta: {
      [props.source]: props.value
    },
  })
  const handleClick = () => {
    redirect('show', AdminResourceName.WALLET, data.id, data);
  }
  if (!data) return null;
  if (isLoading) return (<span>loading...</span>)
  return (<Button onClick={handleClick} children={<span>{getProviderNumber(data)}</span>}/>)
}

export const TransactionAddressField = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  if ((!record.receiverId && props.source === 'destination') || (!record.senderId && props.source === 'source')) return (
    <span>{record[props.source]}</span>)
  return (<DataFromWalletField source="address" value={record[props.source]}/>)
}

const TransactionListFields = (createTransaction: boolean) => {
  const fields = [
    <TextField key="id" source="id"/>,
    <TextField key="type" source="type"/>,
    <TextField key="sendAsset" source="sendAsset"/>,
    <TextField key="sendAmountGross" source="sendAmountGross"/>,
    <TextField key="sendAmountNet" source="sendAmountNet"/>,
    <TextField key="receivedAsset" source="receivedAsset"/>,
    <TextField key="receivedAmountGross" source="receivedAmountGross"/>,
    <TextField key="receivedAmountNet" source="receivedAmountNet"/>,
    <TextField key="fee" source="fee"/>,
    <TextField key="state" source="state"/>,
    <ReferenceField key="boundedTransactionId" label="Parent transaction" source="boundedTransactionId"
                    reference={AdminResourceName.TRANSACTION_ALL} link={'show'}>
      <TextField source="id"/>
    </ReferenceField>,
    <TransactionAddressField key="source" label="Source" sortBy="source" source="source"/>,
    <TransactionAddressField key="destination" label="Destination" sortBy="destination" source="destination"/>,
    <TextField key="reference" source="reference"/>,
    <TextField key="beneficiary" source="beneficiary"/>,
    <FunctionField key="senderId" label="Sender" sortBy="senderId"
                   render={(record: ITransactionModel) => senderName(record)}/>,
    <FunctionField key="receiverId" label="Receiver" sortBy="receiverId"
                   render={(record: ITransactionModel) => receiverName(record)}/>,
    <DateField key="createdAt" source="createdAt"/>,
    <DateField key="updatedAt" source="updatedAt"/>,
    <ShowButton key="show"/>,
  ];
  if (createTransaction) {
    fields.push(<EditButton key="edit"/>)
  }
  return fields
}

export const TransactionList = () => {
  const { isLoading, permissions } = usePermissions();
  if (isLoading) return null;
  const createAccrualTransactionPermission = !!(RolePermissions[permissions].find(({ action, resource }) => {
    return (resource === AdminResourceName.CREATE_ACCRUAL_TRANSACTION && action === RoleAction.CREATE) ||
      (resource === AdminResourceName.ALL &&
        (action === RoleAction.CREATE || action === RoleAction.ALL)
      )
  }));
  return (
    <List
      actions={<ListActions createTransaction={createAccrualTransactionPermission}/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}
    >
      <GridComponent bulkActionButtons={false}
                     gridChildren={TransactionListFields(createAccrualTransactionPermission)}/>
    </List>
  )
}
