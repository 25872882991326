import {
  List,
  DateField,
  TextField,
  TopToolbar,
  ExportButton,
  ReferenceField,
  FunctionField,
  Pagination,
} from 'react-admin';
import { Box } from '@mui/material';
import { TransactionFilterButton } from '../transaction';
import { ApiLogFilterForm } from '../api-log';
import { ROWS_PER_PAGE } from '../pagination/pagination.const';
import { GridComponent } from '../grid/grid-component';
import { AdminResourceName } from '../../resource-name.enum';

const ListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <TransactionFilterButton/>
      <ExportButton/>
    </TopToolbar>
    <ApiLogFilterForm/>
  </Box>
);

const ApiLogFieldsList =()=>{
  return [
    <TextField key="id" source="id"/>,
    <TextField key="method" source="method"/>,
    <FunctionField key="url" label="url" render={(record: any) => record.url.slice(0, 50)}/>,
    <TextField key="queryString" source="queryString"/>,
    <FunctionField key="body" label="body" render={(record: any) => record?.body ? record.body.slice(0, 50) : ''}/>,
    <FunctionField key="headers" label="headers" render={(record: any) => record?.headers ? record.headers.slice(0, 50) : ''}/>,
    <TextField key="contentType" source="contentType"/>,
    <FunctionField key="response" label="response" render={(record: any) => record?.response ? record.response.slice(0, 50) : ''}/>,
    <TextField key="status" source="status"/>,
    <ReferenceField key="user" label="User" source="userId" reference={AdminResourceName.USER}>
      <TextField source="email"/>
    </ReferenceField>,
    <ReferenceField key="admin" label="Admin" source="userId" reference="admin">
      <TextField source="email"/>
    </ReferenceField>,
    <TextField key="ip" source="ip"/>,
    <TextField key="duration" source="duration"/>,
    <DateField key="createdAt" source="createdAt"/>,
    <DateField key="updatedAt" source="updatedAt"/>,
  ]
}

export const ApiLogList = () => {
  return (
    <List actions={<ListActions/>} sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE}/>}>
      <GridComponent rowClick="show" bulkActionButtons={false} gridChildren={ApiLogFieldsList()}/>
    </List>
  )
}