import {
  AutocompleteInput,
  Create,
  Form,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin'
import { AdminResourceName } from '../../resource-name.enum';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { CreateTxMode } from './transaction-state';

export const CreateTitle = (props: any) => {
  return (<span>{props.title || 'Create'}</span>)
}

const filterByAccountNumber = (searchText: string) => {
  if (isNaN(parseInt(searchText)))
    return { accountNumber: `0` }
  return { accountNumber: searchText };
};

const optionText = (choice: any) => {
  if (!choice) return 'No data';
  return `${choice?.account?.accountNumber}: ${choice?.asset} :${choice?.account.singleOwner?.email || choice?.account?.users[0]?.user.email}`
}

enum AccountLabel {
  beneficiary = 'Beneficiary',
  sender = 'Sender',
  accrual = 'accrual',
  withdrawal = 'withdrawal',
}

export const TransactionCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [firstWalletLabel, setFirstWalletLabel] = useState(AccountLabel.beneficiary);
  // const [showSecondWallet, setSecondWallet] = useState(false);
  // const [secondWalletLabel, setSecondWalletLabel] = useState(AccountLabel.sender);
  const [sourceAddressLabel, setSourceAddressLabel] = useState(AccountLabel.sender)
  const [amountLabel, setAmountLabel] = useState(AccountLabel.accrual);

  const handleChangeInput = (event: any) => {
    if (Number(event.target.value) < 0) {
      setFirstWalletLabel(AccountLabel.sender);
      // setSecondWalletLabel(AccountLabel.beneficiary)
      setSourceAddressLabel(AccountLabel.beneficiary);
      setAmountLabel(AccountLabel.withdrawal);
    } else {
      setFirstWalletLabel(AccountLabel.beneficiary);
      // setSecondWalletLabel(AccountLabel.sender)
      setSourceAddressLabel(AccountLabel.sender);
      setAmountLabel(AccountLabel.accrual);
    }
  }

  const handleCloseClick = () => {
    redirect(`/${AdminResourceName.TRANSACTION_ALL}`);
  };
  // const toggleShowSecondWallet = () => {
  //   setSecondWallet(!showSecondWallet);
  // }
  const validateForm = (values: any) => {
    const errors = {}

    if (!values.amount) {
      Object.assign(errors, {
        amount: 'The amount is required'
      });
    }
    if (values.amount === 0) {
      Object.assign(errors, {
        amount: 'The amount must be non zero value'
      });
    }
    if (!values.firstWalletId) {
      if (!values.amount || values.amount >= 0) {
        Object.assign(errors, {
          firstWalletId: 'The Beneficiary is required'
        });
      }
      if (values.amount < 0) {
        Object.assign(errors, {
          firstWalletId: 'The Sender is required'
        });
      }
    }
    if (!values.sourceAddress) {
      if (!values.amount || values.amount >= 0) {
        Object.assign(errors, {
          sourceAddress: 'The Sender is required'
        });
      }
      Object.assign(errors, {
        sourceAddress: 'The Beneficiary is required'
      });
    }
    return errors;
  }
  const handleSubmit = async (values: any) => {
    const data = {
      amount: values.amount,
      firstWalletId: values.firstWalletId,
      sourceAddress: values.sourceAddress,
      reference: values.reference,
      mode: CreateTxMode.ALL,
    }
    // if (values.secondWalletId) {
    //   Object.assign(data, { secondWalletId: values.secondWalletId })
    // }
    create(
      AdminResourceName.TRANSACTION_ALL,
      { data }, {
        onSuccess: (data) => redirect('show', AdminResourceName.TRANSACTION_ALL, data.id, data),
        onError: (error: any) => {
          console.log('create TX Error:', error.message);
          notify(`Error: ${error.message}`, { type: 'error', autoHideDuration: 5000, multiLine: true, });
        },
        // onSettled:(data:any)=>{
        //   console.log('create TX onSettled data:',data);
        //   notify('The data sending', { type: 'info', autoHideDuration:500,})
        // }
      }
    );
  }
  return (
    <Create title={<CreateTitle title="Create transaction"/>}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={{
          amount: '',
          firstWalletId: '',
          // secondWalletId: '',
          sourceAddress: '',
          reference: '',
        }}
        validate={validateForm}
        warnWhenUnsavedChanges>
        <Grid container spacing={2} p={3}>
          <Grid item xs={4}>
            <NumberInput
              label={amountLabel}
              source="amount"
              name="amount"
              fullWidth
              onChange={handleChangeInput}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput name="firstWalletId" source="firstWalletId"
                            reference={AdminResourceName.WALLET}
                            perPage={20}
                            enableGetChoices={(filter: any) => {
                              if (!filter?.accountNumber) return false;
                              return filter.accountNumber.trim().length > 1
                            }}
                            allowEmpty
            >
              <AutocompleteInput name="firstWalletId"
                                 label={firstWalletLabel}
                                 filterToQuery={filterByAccountNumber}
                                 optionText={optionText}
                                 optionValue="id"
                                 debounce={300}
                                 fullWidth
                                 emptyText="empty"
                                 emptyValue={''}
                                 shouldRenderSuggestions={(val: string) => {
                                   return val.trim().length > 1
                                 }}
                                 translateChoice={false}
                                 validate={required()}
                // format={value => {
                //   return value == null ? '': value}}
              />
            </ReferenceInput>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Button*/}
          {/*    onClick={toggleShowSecondWallet}*/}
          {/*  >*/}
          {/*    <span>{showSecondWallet ? 'remove second wallet' : 'add second wallet'}</span>*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
          {/*{showSecondWallet &&*/}
          {/*<Grid item xs={12}>*/}
          {/*    <ReferenceInput name="secondWalletId" source="secondWalletId" reference={AdminResourceName.WALLET}*/}
          {/*    >*/}
          {/*        <AutocompleteInput fullWidth name="secondWalletId"*/}
          {/*                           label={secondWalletLabel}*/}
          {/*                           filterToQuery={filterByAccountNumber} optionText={optionText} optionValue="id"*/}
          {/*                           debounce={300}/>*/}
          {/*    </ReferenceInput>*/}
          {/*</Grid>}*/}
          <Grid item xs={8}>
            <TextInput
              label={sourceAddressLabel}
              source="sourceAddress"
              name="sourceAddress"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              label="Reference"
              source="reference"
              name="reference"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleCloseClick}
              sx={{ mr: 4 }}
            >
              <span>Cancel</span>
            </Button>
            <SaveButton label="Save"/>
          </Grid>
        </Grid>
      </Form>
    </Create>
  )
}