import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import {
  PayloadShow,
  receiverName,
  senderName,
  TransactionAddressField,
  TransactionState,
  TransactionType,
} from '../transaction';
import { ITransactionModel } from './domain/transaction.model';
import { AdminResourceName } from '../../resource-name.enum';

export const TransactionTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Transaction: {record.id}"</span>;
};

export const BoundedTransaction = (props: any) => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(AdminResourceName.TRANSACTION_ALL, {
    filter: { type: TransactionType.fee, boundedTransactionId: record.id },
  });
  if (!record) return null;
  if (record.type === TransactionType.fee) {
    return (
      <ReferenceField
        record={record}
        source="boundedTransactionId"
        reference={AdminResourceName.TRANSACTION_ALL}
        link={'show'}
      >
        <TextField source="id" />
      </ReferenceField>
    );
  } else {
    if (isLoading || error) return null;
    if (!data || data.length === 0) return <div>No Data</div>;
    return (
      <ReferenceField record={data[0]} source="id" reference={AdminResourceName.TRANSACTION_ALL} link={'show'}>
        <TextField source="id" />
      </ReferenceField>
    );
  }
};

export const PayeeCountry = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'EUR'
    ) {
      return <TextField label="Country" source="payload.clientOrder.payee.individual.address.country" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'EUR') {
      if (record.payload.clientOrder) {
        return <TextField label="Country" source="payload.clientOrder.payee.individual.address.country" />;
      }

      return <TextField label="Country" source="payload.order.payee.individual.address.country" />;
    }

    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'USD'
    ) {
      return <TextField label="Country" source="payload.clientOrder.country" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'USD') {
      if (record.payload.clientOrder) {
        return <TextField label="Country" source="payload.clientOrder.country" />;
      }

      return <TextField label="Country" source="payload.order.country" />;
    }
  }

  return null;
};

export const PayeeCity = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'EUR'
    ) {
      return <TextField label="City" source="payload.clientOrder.payee.individual.address.city" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'EUR') {
      if (record.payload.clientOrder) {
        return <TextField label="City" source="payload.clientOrder.payee.individual.address.city" />;
      }

      return <TextField label="City" source="payload.order.payee.individual.address.city" />;
    }

    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'USD'
    ) {
      return <TextField label="City" source="payload.clientOrder.city" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'USD') {
      if (record.payload.clientOrder) {
        return <TextField label="City" source="payload.clientOrder.city" />;
      }

      return <TextField label="City" source="payload.order.city" />;
    }
  }

  return null;
};

export const PayeeAddress = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'EUR'
    ) {
      return <TextField label="Street" source="payload.clientOrder.payee.individual.address.street" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'EUR') {
      if (record.payload.clientOrder) {
        return <TextField label="Street" source="payload.clientOrder.payee.individual.address.street" />;
      }

      return <TextField label="Street" source="payload.order.payee.individual.address.street" />;
    }

    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'USD'
    ) {
      return <TextField label="Street" source="payload.clientOrder.address" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'USD') {
      if (record.payload.clientOrder) {
        return <TextField label="Street" source="payload.clientOrder.address" />;
      }

      return <TextField label="Street" source="payload.order.address" />;
    }
  }

  return null;
};

export const PayeeZipCode = (props: any) => {
  const record = useRecordContext();

  if (
    record.type === TransactionType.transfer &&
    record.senderId &&
    !record.receiverId &&
    (record.sendAsset === 'EUR' || record.sendAsset === 'USD')
  ) {
    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'EUR'
    ) {
      return <TextField label="Zip code" source="payload.clientOrder.payee.individual.address.zip" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'EUR') {
      if (record.payload.clientOrder) {
        return <TextField label="Zip code" source="payload.clientOrder.payee.individual.address.zip" />;
      }

      return <TextField label="Zip code" source="payload.order.payee.individual.address.zip" />;
    }

    if (
      (record.state === TransactionState.completed || record.state === TransactionState.failed) &&
      record.sendAsset === 'USD'
    ) {
      return <TextField label="Zip code" source="payload.clientOrder.postalCode" />;
    }

    if (record.state === TransactionState.pending && record.sendAsset === 'USD') {
      if (record.payload.clientOrder) {
        return <TextField label="Zip code" source="payload.clientOrder.postalCode" />;
      }

      return <TextField label="Zip code" source="payload.order.postalCode" />;
    }
  }

  return null;
};

export const TransactionShow = () => {
  return (
    <Show title={<TransactionTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="sendAmountGross" />
        <TextField source="sendAmountNet" />
        <TextField source="receivedAmountGross" />
        <TextField source="receivedAmountNet" />
        <TextField source="fee" />
        <TextField source="sendAsset" />
        <TextField source="receivedAsset" />

        <FunctionField label="Sender" render={(record: ITransactionModel) => senderName(record)} />
        <TransactionAddressField key="source" label="Source" sortBy="source" source="source" />
        <ReferenceField
          key="SenderAccountNumber"
          label="Sender account number"
          source="senderId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <TextField source="accountNumber" />
        </ReferenceField>

        <FunctionField label="Receiver" render={(record: ITransactionModel) => receiverName(record)} />
        <TransactionAddressField key="destination" label="Destination" sortBy="destination" source="destination" />
        <ReferenceField
          key="ReceiverAccountNumber"
          label="Receiver account number"
          source="receiverId"
          reference={AdminResourceName.BUSINESS_ACCOUNT}
          link="show"
        >
          <TextField source="accountNumber" />
        </ReferenceField>
        <PayeeCountry label="Payee country" />
        <PayeeCity label="Payee city" />
        <PayeeAddress label="Payee address" />
        <PayeeZipCode label="Payee zip code" />

        <TextField source="reference" />
        <TextField source="beneficiary" />
        <TextField source="state" />
        <TextField source="type" />
        <BoundedTransaction label="Related transaction" />
        <PayloadShow source="payload" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};
