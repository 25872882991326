import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { ReturnButton } from '../saving-plan';
import { AdminResourceName } from '../../resource-name.enum';

export enum SavingStatusEnum {
  open = 'Open',
  closed = 'Closed',
}

export const SavingTitle = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>"Saving: {record.id}"</span>;
};

export const SavingStatus = (props: any) => {
  const record: { withdrawDate: string } = useRecordContext();
  if (!record) return null;
  let status = SavingStatusEnum.open;
  if (record.withdrawDate) {
    status = SavingStatusEnum.closed;
  }
  // console.log('record')
  // console.log(record)
  return <span>{status}</span>;
};

export const SavingShow = () => {
  return (
    <Show title={<SavingTitle />}>
      <SimpleShowLayout>
        <TextField label="Saving ID" source="id" />
        <TextField label="Wallet ID" source="walletId" />
        <ReferenceField
          label="Client's email"
          source="user.id"
          reference={AdminResourceName.USER}
          link={(record) => {
            return `/business_account/${record.wallet.accountId}/show`;
          }}
        >
          <TextField source='email'/>
        </ReferenceField>
        <FunctionField
          label="Client's name"
          render={(record: any) => {
            return [record.profile.firstName, record.profile.lastName].filter((v) => !!v).join(' ');
          }}
        />
        <DateField label="Created at" source="createdAt" />
        <DateField label="Updated at" source="updatedAt" />
        {/*<TextField source="dailyIncome"/>*/}
        <DateField label="Maturity date" source="maturityDate" />
        <DateField label="Withdraw date" source="withdrawDate" />
        <SavingStatus label="Status" />
        <TextField label="Amount" source="amount" />
        <TextField label="Currency" source="asset.shortcode" />
        <ReturnButton />
      </SimpleShowLayout>
    </Show>
  );
};
