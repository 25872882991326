import { config } from '../config';
import { enable2faDataInterface } from './interfaces/enable-2fa-data.interface';

const authBaseUrl = `${config.API_HOST}/auth/admin`;
export const HTTP_SUCCESS = 200;
export const HTTP_FORBIDDEN = 403;
export const HTTP_BAD_REQUEST = 400;
// const HTTP_UNAUTHORIZED = 401;
// const HTTP_CREATED = 201;

export async function createSession(email: string, password: string, token2fa?: string) {
  const body = {
    email: email,
    password
  }
  if (token2fa) {
    Object.assign(body, { token2fa });
  }
  const response = await fetch(`${authBaseUrl}/login`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  });
  const json = await response.json();
  switch (response.status) {
    case HTTP_SUCCESS:
      break;
    case HTTP_FORBIDDEN:
      throw new Error(JSON.stringify({
        status: response.status,
        message: json.message,
      }))
    case HTTP_BAD_REQUEST:
      throw new Error(JSON.stringify({
        status: response.status,
        message: json.message,
      }))
    default:
      throw new Error(json.message);
  }
  return json;
}

export async function validateSession(token: string) {
  if (!token) throw new Error('Not authorized');

  const response = await fetch(`${authBaseUrl}/validate`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const json = await response.json();
  if (response.status !== HTTP_SUCCESS) {
    throw new Error(json.message);
  }
  return json;
}

export async function getSessionPermission(token: string) {
  if (!token) throw new Error('Not authorized');

  const response = await fetch(`${authBaseUrl}/permissions`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` }
  });
  const json = await response.json();
  // console.log('permission-json');
  // console.log(json);
  if (response.status !== HTTP_SUCCESS) {
    throw new Error(json.message);
  }

  return json;
}

export async function get2faQrCode(token: string) {
  if (!token) throw new Error('Not authorized');

  const response = await fetch(`${authBaseUrl}/two-factor-auth/generate-qrcode`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` }
  });
  const json = await response.json();
  if (response.status !== HTTP_SUCCESS) {
    throw new Error(json.message);
  }

  return json;
}

export async function enableTwoFA(token: string, data: { token: string }): Promise<enable2faDataInterface> {
  if (!token || !data?.token) throw new Error('Not authorized');
  const response = await fetch(`${authBaseUrl}/two-factor-auth/enable`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  const json = await response.json();
  if (response.status !== HTTP_SUCCESS) {
    throw new Error(json.message);
  }

  return json as enable2faDataInterface;
}